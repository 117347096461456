import { Spin } from "antd";
import React from "react";
import loading_anime from "../assets/loading_anime.gif";
import ReactLoading from "react-loading";
import Skeleton from "react-skeleton-loader";
const SpinLoading = (props) => {
  return (
    <Spin
      wrapperClassName={` h-full word-card-loader ${
        props.loading && "global-loader bg-white"
      } ${props.indexload && "index-loader"} `}
      size="large"
      indicator={
        <div
          className="flex justify-center flex-col items-center"
          style={{ transform: "scale(1.5)" }}
        >
          <div>
            <img src={loading_anime} className="" style={{ width: "200px" }} />
          </div>
          <div>
            <ReactLoading
              type="bubbles"
              color="#10add1"
              height={50}
              width={25}
            />
          </div>
        </div>
      }
      spinning={props.loading || false}
      tip={""}
      // style={{ zIndex: 1, marginTop: '2.5rem' }}
    >
      {!props.loading && props.children}
    </Spin>
  );
};
export const SkeletonLoader = () => {
  return (
    <div className="p-2 flex flex-col">
      <Skeleton
        width={"250px"}
        height={"200px"}
        loading={true}
        active
        size={"large"}
      />
      <Skeleton
        width={"200px"}
        height={"15px"}
        loading={true}
        active
        size={"large"}
      />
      <Skeleton
        width={"180px"}
        height={"15px"}
        loading={true}
        active
        size={"large"}
      />
    </div>
  );
};
export default SpinLoading;
