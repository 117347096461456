export const LoginUser = "/users/login";
export const LogoutUser = "/users/logout";
export const SignupUser = "/users/registration";
export const ForgetPasswordUser = "/users/forgot-password";
export const CheckOTP = "/users/check/otp";
export const ChangePassword = "users/change/password";
export const WordOfTheDay = "/users/words";
export const WordDetail = "/users/word/detail";
export const OTPVerification = "/users/verification";
export const WordHyperLink = "/users/word/wordhyperlink";
export const FeaturedData = "/users/featuredata";
export const RecentData = "/users/userrecentview";
export const UpdateProfile = "/users/update/profile";
export const tamilWordSearch = "/users/tamilword/search";
export const englishWordSearch = "/users/englishword/search";
export const GetSaved = "/users/bookmark/details";
export const GetUserProfile = "/users/details";
export const GetTotalSaved = "/users/totalbookmark/details";
export const SaveWord = "/users/bookmark";
export const ResetPassword = "/users/reset-password";
// export const RateUs = "/users/rate_us";
export const FeedbackApi = "/users/feedback_complaint";
export const UpdateLanguage = "/users/update/language";
export const UpdateSetting = "/users/update/setting";
export const GetUserSettings = "/users/get-user-settings";
export const WordSearch = "/users/word/search";
export const WordSearchAPI = "/users/word_search";
export const GoogleSignCheck = "/users/googleSignCheck";
export const TamilnameUpdation = "/users/tamilname/updation";
export const KeyboardUpdation = "/users/keybaord_update";
export const AccuntDelete = "/users/delete-account";
//BASE_URL
// export const BASEURL="https://minagaram.com/api"
// export const BASEURL="http://localhost:3001/api"
export const BASEURL = "http://13.126.65.58/api";

export const VERSION = "1.6a";
// export const BASEURL="http://localhost:3002/api"
// export const BASEURL="http://192.168.10.80:3002/"
