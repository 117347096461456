import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Routing from "../src/Router/Routing";
import { createBrowserHistory, createHashHistory } from "history";
import { isElectron } from "./utils";
import { message } from "antd";
import Offline from "./Pages/Offline/Offline";
import "antd/dist/antd.min.js";
import SpeechRecognition from "react-speech-recognition";
import { connect } from "react-redux";
// import 'antd/dist/antd.css';
// import 'antd/dist/reset.css';

export const history = isElectron()
  ? createHashHistory()
  : createBrowserHistory();

function App() {
  const [isOnline, setNetwork] = React.useState(window.navigator.onLine);

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
    message.config({
      duration: 5,
    });
    if (window.navigator.onLine === true) {
      const isTamil = localStorage.getItem("agarathi_isTamil");
      message.success(
        isTamil === "false"
          ? "Your internet connection was restored"
          : "உங்கள் இணையத் தொடர்பு புதுப்பிக்கப்பட்டுள்ளது"
      );
    }
  };
  React.useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  });
  return (
    <Router history={history}>
      {isOnline === true ? (
        <>
          <Routing />
        </>
      ) : isOnline === false ? (
        <Offline />
      ) : null}
    </Router>
  );
}

export default App;
